import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";

export default function WhyUs(): ReactElement {
    return (
        <section className={` relative ${styles.description}`}>
            <div className="flex items-center py-6 px-2 lg:p-16 gap-16 flex-col lg:flex-row">
                <div className="lg:w-1/2 ">
                    <h3 className="text-3xl mb-6">
                        Water Contamination at Camp Lejeune
                    </h3>
                    <p className="text-lg font-light">
                        The water supply at Camp Lejeune was contaminated with
                        high levels of toxic chemicals, including benzene,
                        trichloroethylene (TCE), tetrachloroethylene,
                        perchloroethylene (PCE), and vinyl chloride. These
                        chemicals are known to cause cancer. The contamination
                        occurred through various means, such as leaks from
                        garbage dumps and underground storage tanks that allowed
                        the chemicals to enter the water system. Even though the
                        tanks were shut down in February 1985, those exposed to
                        the toxic water still suffer from severe and sometimes
                        life-threatening illnesses.
                    </p>
                </div>
                <div className="w-[300px] h-[270px] lg:w-[548px] lg:h-[465px] relative">
                    <Image
                        src="/topcamplejeunelawyers/assets/images/lawsuit.jpg"
                        layout="fill"
                        alt="waste"
                    />
                </div>
            </div>
        </section>
    );
}
